<template>
    <div class="foots">
        <div class="box">
            <div class="box-text">深圳市南山区科兴科学园A1栋2层</div>
            <div class="box-text">版权所有&nbsp;深圳市星辰大海供应链有限公司 <a class="a-color" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">粤ICP备2022141891号</a></div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped lang="scss">
.foots{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box {
        text-align: center;
        margin-top: 55px;
        .box-text {
            color:#9fa2aa;
            font-size: 18px;
            .a-color {
                color: #9fa2aa;
            }
        }
    }
}
.foots>p{
    margin-top: 85px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.foots .list{
    width: 1440px;
    height: 153px;
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.foots .list .list-content{
    height: 153px;
}
.foots .list .list-content ul{

}
.foots .list .list-content ul li{
    margin-bottom: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.foots .list .list-content ul li h1{
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 0;
}
.foots .list .list-content ul li:nth-child(5){
    margin-bottom: 0;
}
.foots .list .list-code{
    display: flex;
    align-items: center;
}
.foots .list .list-code .code-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 53px;
}
.foots .list .list-code .code-data img{
    width: 104px;
    height: 104px;
}
.foots .list .list-code .code-data span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 16px;
}
</style>
