<template>
    <div class="about">

        <Head :AboutView="true"></Head>
        <div class="bg-img">
            <div class="photo-title">
                <div class="copywriting-o">星辰大海, 商机无限!</div>
                <div class="copywriting-t">
                    <div class="copywriting-center">
                        <div class="copywriting-text">生活智能</div>
                        <div class="copywriting-text">智能手表</div>
                        <div class="copywriting-text">日常推广</div>
                        <div class="copywriting-text">用户至上</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 分类 -->
        <div class="class-a">
            <div class="class-flex">
                <img class="class-b" alt="" @click="skipClass(1)"
                    src="https://supplychainimg.quanminyanxuan.com/other/e43d4d8d7ded42fcb37acf8848143642.png">
                <img class="class-c" alt="" @click="skipClass(2)"
                    src="https://supplychainimg.quanminyanxuan.com/other/6351b4dc5a0e48d4876dd7722c09a95f.png">
                <img alt="" class="class-c" @click="skipClass(3)"
                    src="https://supplychainimg.quanminyanxuan.com/other/f79d16947b6540109959f10bb6834ff5.png">
                <img alt="" class="class-c" @click="skipClass(4)"
                    src="https://supplychainimg.quanminyanxuan.com/other/884c2543396742f180addb62c241dd97.png">
                <img alt="" class="class-c" @click="skipClass(5)"
                    src="https://supplychainimg.quanminyanxuan.com/other/6eccaf18a8ea4ce79fc10612a8fcfa92.png">
            </div>
        </div>
<!--        &lt;!&ndash; 入驻流程 资费资质 &ndash;&gt;-->
<!--        <div class="aptitude">-->
<!--            &lt;!&ndash; 入驻流程 &ndash;&gt;-->
<!--            <img class="enter" @click="settlingProcess"-->
<!--                src="https://supplychainimg.quanminyanxuan.com/other/ad5e13863afd412f9b1c46eb4d56b766.png"-->
<!--                alt="">-->
<!--            &lt;!&ndash; 资费资质 &ndash;&gt;-->
<!--            <img class="enter enter-left" @click="tariff"-->
<!--                src="https://supplychainimg.quanminyanxuan.com/other/50e16f9be0dd479587f65998fde588c0.png"-->
<!--                alt="">-->
<!--        </div>-->

        <!-- 联系招商 -->
        <div class="copywriting">
            <div class="con-s">优秀案例</div>
            <div class="margin-center">
                <div class="text-copy">
                    <div class="copywriting-box" v-for="(item,index) in copywritingList"
                        :key="index">
                        <img class="copywriting-img" :src="item.url" alt="">
                        <div>
                            <div class="copy-title">{{ item.title }}</div>
                            <div class="copy-text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- 联系招商 -->
<!--        <div class="connection">-->
<!--            <div class="con-t">联系招商</div>-->
<!--            <div class="user">-->
<!--                &lt;!&ndash; 用户卡片 &ndash;&gt;-->
<!--                <div :class="index < 1 ? 'user-o':'user-o user-s'" v-for="(item,index) in userList" :key="index">-->
<!--                    <div class="cart-title">-->
<!--                        <div class="color-r"> </div>-->
<!--                        <div class="color-title">{{ item.title }}</div>-->
<!--                    </div>-->
<!--                    <img class="imgSize" :src="item.url" alt="">-->
<!--                    <div class="info">-->
<!--                        <div class="info-name">-->
<!--                            <img class="info-img"-->
<!--                                src="https://supplychainimg.quanminyanxuan.com/other/cbdaef6a9a394f28bf98a4658a1b58b7.png"-->
<!--                                alt="">-->
<!--                            <div class="name-i">{{ item.name }}</div>-->
<!--                        </div>-->
<!--                        <div class="info-name info-name-a">-->
<!--                            <img class="info-img"-->
<!--                                src="https://supplychainimg.quanminyanxuan.com/other/e988cfbc4b544570b4444effc990dd3b.png"-->
<!--                                alt="">-->
<!--                            <div class="name-i">{{ item.phone }}</div>-->
<!--                        </div>-->
<!--                        <div class="info-name info-name-a">-->
<!--                            <img class="info-img"-->
<!--                                src="https://supplychainimg.quanminyanxuan.com/other/b643c37fe3bf47ea81a2b78bc462b460.png"-->
<!--                                alt="">-->
<!--                            <div class="name-i">{{ item.mail }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
        <div class="foot">
            <Footer></Footer>
        </div>
    </div>
</template>
  <script>
import Head from '../components/Head'
import Footer from '../components/Footer'

export default {
    components: {
        Head,
        Footer
    },
    data () {
        return {
            userList: [
                {
                    title: '家电3C数码',
                    url: '',
                    name: '',
                    phone: '',
                    mail: ''
                },
                {
                    title: '运动服饰户外',
                    url: '',
                    name: '',
                    phone: '',
                    mail: ''
                },
                {
                    title: '家居家纺百货',
                    url: '',
                    name: '',
                    phone: '',
                    mail: ''
                },
                {
                    title: '食品酒水生鲜',
                    url: '',
                    name: '',
                    phone: '',
                    mail: ''
                },
                {
                    title: '个护美妆母婴',
                    url: '',
                    name: '',
                    phone: '',
                    mail: ''
                },
            ],
            copywritingList: [
                {
                    url: 'https://supplychainimg.quanminyanxuan.com/other/a0383c08daf0491697802b27c606a5fd.jpg',
                    title: '无人机',
                    text: '无人机用品于2022年5月入驻玩具乐器类目，主打玩具智能产品，基于对年轻人群的深度洞察，店铺把18-35岁人群定为购买主力军，其次是25-29岁人群。入驻当月就高达百万销售额'
                },
                {
                    url: 'https://supplychainimg.quanminyanxuan.com/other/34e0b7777c8d4837ac3a67553bd2623c.jpg',
                    title: '智能儿童手表',
                    text: '随着智能化产品开始普及，儿童手表也成为了一个备受关注的话题。儿童手表是一种为儿童设计的智能手表，它既可以显示时间，还可以进行通话、定位、健康监测、学习辅助等功能。儿童手表参与提报开学季主题活动，利用一些营销工具，寻找精准流量，在短短几天活动销量增加300%'
                },
                {
                    url: 'https://supplychainimg.quanminyanxuan.com/other/d95a0aa7e50b424495f505f3d498f291.jpg',
                    title: '希尔顿五星级酒店枕芯护颈枕',
                    text: '平台通过站内拉新以及平台资源的善用，为品牌造势，结合站内+站外的模式，提高产品渗透率，强化品牌影响力。站内以日常推广结合星辰大海平台强大的营销大促资源，站外以达人带货结合内容种草全面推广开来，成功实现流量增长，业绩创新高达到250w。'
                },
                {
                    url: 'https://supplychainimg.quanminyanxuan.com/other/ea3e8cdc65d84bbfa95a54298f06fee6.jpg',
                    title: '爱恩倍居家必备薰衣草洗衣液',
                    text: '自从入驻平台以来，月平均成交额一直保持在30万以上。然而，最厉害之处在于能够充分利用星辰大平台活动来带动店铺总体营业额的提升。一场常规的“天天特卖”活动，活动总成交订单数超过3000单，总成交额超过10万。'
                },
            ]

        }
    },
    created(){
        document.body.scrollTop = 0
    },
    methods: {
        skipClass (type) {
            this.$router.push({
                name: 'classDetail',
                query: {type: type}
            })
        },
        settlingProcess () {
            this.$router.push({ name: 'about' })
        },
        tariff (){
            this.$router.push({
                name: 'packCoupon',
            })
            
        }
    },
}
  </script>
  <style scoped lang="scss">
.about {
    width: 100%;
    background-color: #f5f5f5;
}
.bg-img {
    background-image: url(https://supplychainimg.quanminyanxuan.com/other/34557609527941bda66b92baef830352.png);
    background-size: 100% 100%;
    width: 100%;
    height: 570px;
    margin-top: 91px;
    position: relative;

    .photo-title {
            .copywriting-o {
                font-size: 80px;
                color: #fff;
                padding-top: 126px;
                padding-left: 20%;
            }
            @media only screen and (min-width: 1366px) and (max-width:1760px)  {
                .copywriting-o {
                    padding-left: 5%;
                }
            }
            
      
        .copywriting-t {
            width: 60%;
            font-size: 50px;
            color: #fff;
            margin-left: 41px;
            padding-left: 18%;
            
            .copywriting-center {
                display: flex;
                width: 760px;
                font-size: 27px;
                margin-top: 11px;
                .copywriting-text {
                    width: 125px;
                    border: 2px solid #fffffb;
                    border-radius: 10px;
                    text-align: center;
                    margin-right: 5px;
                    line-height: 36px;

                }
            }
        }
        @media only screen and (min-width: 1366px) and (max-width:1760px)  {
            .copywriting-t {
                padding-left: 3%;
            }
        }
    }
}
.class-a {
    height: 130px;
    background-color: #ffffff;
    cursor: pointer;
    .class-flex {
        display: flex;
        justify-content: center;
    }
    .class-b {
        margin-top: 21px;
        width: 210px;
    }
    .class-c {
        margin-left: 21px;
        margin-top: 15px;
        width: 210px;
    }
}
.aptitude {
    margin-top: 36px;
    margin-bottom: 42px;
    display: flex;
    justify-content: center;
    
    .photo-o {
        width: 750px;
        height: 240px;
    }
    .enter {
    cursor: pointer;

        width: 593px;
        .enter-left {
            margin-left: 31px;
        }
    }
}
.copywriting {
    background-color: #ffffff;
    padding-bottom: 34px;
    .con-s {
        font-size: 36px;
        text-align: center;
        padding-top: 30px;
        margin-bottom: 20px;
    }
    .margin-center {
        justify-content: center;
        display: flex;
        .text-copy {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            .copywriting-box {
                width: 274px;
                background: #ffffff;
                box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                .copywriting-img {
                    width: 100%;
                    border-radius: 7px 7px 0 0;
                }
                .copy-title {
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 25px;
                    text-align: center;
                    margin-top: 13px;
                }
                .copy-text {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 19px;
                    padding: 10px 24px 17px 24px;
                }
            }
        }
    }
}
.connection {
    .con-t {
        font-size: 36px;
        text-align: center;
        padding-top: 30px;
    }
    .user {
        height: 404px;
        margin-top: 45px;
        display: flex;
        justify-content: center;
        .user-o {
            width: 227px;
            height: 286px;
            background: #ffffff;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            text-align: center;
            .imgSize {
                width: 121px;
                height: 121px;
                text-align: center;
                line-height: 28px;
            }
            .cart-title {
                height: 30px;
                margin-top: 17px;
                display: flex;
                .color-r {
                    width: 4px;
                    height: 20px;
                    background-color: #3b8dff;
                    margin: 0 10px 0 22px;
                }
                .color-title {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 20px;
                }
            }
            .info {
                height: 50px;
                margin-top: 17px;
                .info-name {
                    display: flex;
                    .info-img {
                        width: 11px;
                        height: 13px;
                        margin: 6px 6px 0 14px;
                    }
                    .name-i {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }
                }
                .info-name-a {
                    margin-top: 13px;
                }
            }
        }
        .user-t {
            margin-left: 200px;
        }
        .user-s {
            margin-left: 26px;
        }
    }
}

.bg-img .index-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-img .index-content h1 {
    font-size: 80px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.bg-img .index-content h5 {
    width: 967px;
    height: 102px;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 63px;
}

.index-content .role-entrance {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 960px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    background: hsla(0, 0%, 100%, 0.3);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    margin-top: 70px;
}

.index-content .role-entrance .role-item:hover {
    background-color: #04b940;
    border-radius: 10px;
}

.index-content .role-entrance .role-item:hover ~ .item-div {
    display: none;
}

.index-content .role-entrance .item-div {
    width: 1px;
    height: 86px;
    background: #ffffff;
}

.index-content .role-entrance .role-item {
    position: relative;
    box-sizing: border-box;
    height: 120px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.index-content .role-entrance .role-item:hover .item-code {
    display: block;
}

.index-content .role-entrance .role-item .item-code {
    width: 238px;
    position: absolute;
    top: 143px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1001;
    display: none;
}

.index-content .role-entrance .role-item .item-code img {
    width: 238px;
    height: 253px;
}

.index-content .role-entrance .role-item p {
    width: 238px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    margin-top: 20px;
    text-align: center;
}

.index-content .role-entrance .role-item .info {
    color: #fff;
}

.index-content .role-entrance .role-item .info .title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.index-content .role-entrance .role-item .info .subtitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.index-content .role-entrance .role-item .icon {
    width: 19px;
    height: 36px;
    margin-left: 100px;
}

.foot {
    width: 100%;
    height: 150px;
    background: #192035;
}
.foot .list {
    width: 1440px;
    height: 153px;
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.foot .list .list-content {
    height: 153px;
}
.foot .list .list-content ul {
}
.foot .list .list-content ul li {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}
.foot .list .list-content ul li h1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
}
.foot .list .list-content ul li:nth-child(5) {
    margin-bottom: 0;
}
.foot .list .list-code {
    display: flex;
    align-items: center;
}
.foot .list .list-code .code-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 53px;
}
.foot .list .list-code .code-data img {
    width: 104px;
    height: 104px;
}
.foot .list .list-code .code-data span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 16px;
}
.foot > p {
    margin-top: 85px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}
</style>
